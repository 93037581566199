<template>
  <div class="footer">
    <div class="logos">
      <div class="icon1" @click="goToEarch">
          <img class="img1" src="@/assets/img/指点地球村.png">
          <div class="text1">指点地球村</div>
      </div>
      <div class="icon2" data-clipboard-text="wwwi2b2b" data-clipboard-action="copy" @click="copyWX">
          <img class="img2" src="@/assets/img/vx.png">
          <div class="text2">微信</div>
      </div>
      <a class="icon3" href="tel:13817169848">
          <img class="img3" src="@/assets/img/phone.png">
          <div class="text3">手机</div>
      </a>
    </div>
    <div class="reserve" @click="reserve">立即预约</div>
  </div>
</template>

<script>
import ClipboardJS from 'clipboard'

export default {
  name: 'Footer',
  methods: {
    reserve () {
      this.$router.push({
        path: '/nowreserve'
      })
    },
    goToEarch () {
      if (/(Android)/i.test(navigator.userAgent)) {
        window.location.href = 'https://appgallery.huawei.com/app/C102176069?sharePrepath=ag&locale=zh_CN&source=appshare&subsource=C102176069&shareTo=weixin&shareFrom=appmarket'
      } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.location.href = 'https://apps.apple.com/cn/app/%E6%8C%87%E7%82%B9%E5%9C%B0%E7%90%83%E6%9D%91/id1571102482'
      }
    },
    copyWX () {
      var clipboard = new ClipboardJS('.icon2')
      clipboard.on('success', function () {
        alert('微信已成功复制')
      })
    },
    goToPhone () {
      window.location.href = 'tell:// 18721251750'
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 0.44rem;
  z-index: 10;
  transform:translateZ(10);
  background-color: rgba(255, 255, 255, 1)
}
.logos {
  display: flex;
  margin: 0.05rem 0 0.55rem 0.25rem;
}
.icon1 {
  margin-right: 0.265rem;
  .img1 {
    width: 0.19rem;
    height: 0.19rem;
    padding-left: 0.15rem;
  }
  .text1 {
    font-size: PingFangSC-Regular;
    font-size: 0.1rem;
    color: #666666;
  }
}
.icon2 {
  margin-right: 0.265rem;
  .img2 {
    width: 0.195rem;
    height: 0.16rem;
    margin-bottom: 0.03rem;
  }
  .text2 {
    font-size: PingFangSC-Regular;
    font-size: 0.1rem;
    color: #666666;
  }
}
.icon3 {
  text-decoration: none;
  margin-right: 0.4rem;
  .img3 {
    width: 0.165rem;
    height: 0.17rem;
    margin-bottom: 0.02rem;
    padding-left: 0.02rem;
  }
  .text3 {
    font-size: PingFangSC-Regular;
    font-size: 0.1rem;
    color: #666666;
  }
}
.reserve {
  flex: 1;
  height: 0.44rem;
  line-height: 0.44rem;
  text-align: center;
  font-size: 0.15rem;
  font-family: PingFangSC-Regular;
  color: #ffffff;
  background: linear-gradient(to right,#6BADFD,#3974FB);
}
</style>
